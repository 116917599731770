<template>
  <div class="need-friends">
    <div class="wrapper">
      <!-- <div class="links">
        <nuxt-link to="/users">Показать еще <i class="arrow-right" /></nuxt-link>
        <button @click="showLikesApp = true" class="play">
          <i class="heart" />
          Играть в лайки
        </button>
      </div> -->
      <div class="body">
        <button @click="addTo" class="push">
          <span>Хочу общаться!</span>
        </button>
        <div class="nf-slider">
          <ul
            v-if="usersdata.length"
            :style="`width: ${(usersdata.length + 1) * 135}px`"
          >
            <li v-for="user in usersdata" :key="`need-friends-${user._id}`" :title="user.name">
              <div class="wrap">
                <aura-component :user="user" />
                <artifact-component v-if="user.artifact" :user="user" />
                <lvl-component v-if="user.exp" :exp="user.exp" />
                <div :class="`${user.aura_color ? `aura-color-${user.aura_color}` : ''} ${user.gender ? `gender-${user.gender}` : ''}`" class="avatar">
                  <img :data-src="user.photo" src="/static_files/no-poster.png" :alt="user.name" class="lazyload">
                </div>
              </div>
              <strong>{{ user.name }}</strong>
              <nuxt-link :to="`/users/${user._id}`">
                {{ user.name }}
              </nuxt-link>
            </li>
            <li v-if="showMore && usersdata.length > 9" @click="getData(++page)" style="cursor:pointer;">
              <div class="avatar" />
              <strong>Еще...</strong>
            </li>
          </ul>
          <ul
            v-else
            class="mock"
            :style="`width: ${(10 + 1) * 135}px`"
          >
            <li v-for="i in 10" :key="`need-friends-${i}`">
              <div class="wrap">
                <div class="avatar">
                  <img src="/static_files/no-poster.png" alt="" class="lazyload">
                </div>
              </div>
              <strong></strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <likes-app v-if="showLikesApp" @close="showLikesApp = false" />

    <transition name="toBottom">
      <div v-if="showModal" class="modal">
        <h3>
          Ты уже в ленте
        </h3>
        <i class="willpower" />
        <div>
          <button v-if="!showBalanceUp" @click="upProfile" class="dbtn green">
            <strong>На 1 место</strong>
            <sup>10 Монет</sup>
          </button>
          <nuxt-link v-else to="/donate/" tag="button" class="dbtn green">
            &#128179; Пополнить
          </nuxt-link>
          <span @click="showModal = false">Отмена</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  hydrateOnInteraction
} from 'vue-lazy-hydration'
import { mapState } from 'vuex'

export default {
  props: { nfdata: { type: Array, require: true, default: () => [] } },
  watch: {
    changeRoute(val) {
      if (val.includes('cinema')) {
        clearInterval(this.getDataInterval)
        this.getDataInterval = null
      }
    },
    fullPath() {
      this.showLikesApp = false
      this.showModal = false
      this.showBalanceUp = false
    },
    showLikesApp() {
      if (this.showLikesApp) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  components: {
    likesApp: hydrateOnInteraction(() => import('~/components/likesApp.vue'), { event: 'click' }),
    lvlComponent: () => import('~/components/lvlComponent.vue'),
    auraComponent: () => import('~/components/auraComponent.vue'),
    artifactComponent: () => import('~/components/artifactComponent.vue')
  },
  computed: {
    ...mapState(['user']),
    changeRoute() {
      return this.$route.name
    },
    fullPath() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      page: 1,
      usersdata: [],
      showLikesApp: false,
      gets: {},
      showMore: true,
      showModal: false,
      showBalanceUp: false,
      getDataInterval: null
    }
  },
  mounted() {
    this.getData()
    this.getDataInterval = setInterval(() => {
      // if (this.$route.name !== 'cinema-id') {
        this.getData()
      // }
    }, 10 * 60 * 1000)
    const slider = document.querySelector('.need-friends .nf-slider')
    if (slider) {
      slider.addEventListener('scroll', this.lazyLoadImgs)
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.getDataInterval)
  // },
  methods: {
    async upProfile() {
      if (this.gets.upProfile) return
      this.gets.upProfile = true
      const { data } = await this.$axios.post('/api/need-friends/up')
      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        if (data.error.statusCode === 407) {
          this.showBalanceUp = true
        }
      } else {
        this.showModal = false
        this.getData()
      }
      delete this.gets.upProfile
    },
    async addTo() {
      if (this.gets.addTo) return
      if (!this.user || !this.user.id) {
        this.$store.commit('setError', { status: 'info', msg: 'Необходимо войти на сайт' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        this.$store.commit('setShowAuthForm', true)
        return
      }
      if (!this.user.photo) {
        this.$store.commit('setError', { status: 'info', msg: 'Необходимо установить аватар' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }
      this.gets.addTo = true
      const { data } = await this.$axios.post('/api/need-friends')
      if (data.error) {
        if (data.error.statusCode === 901) {
          this.showModal = true
        } else {
          this.$store.commit('setError', { status: 'info', msg: data.error.message })
        }
      } else {
        this.$store.commit('setError', { status: 'ok', msg: 'Теперь ты в ленте!' })
        const user = this.user
        user._id = user.id
        delete user.id
        this.usersdata.unshift(user)
      }
      delete this.gets.addTo
      setTimeout(() => this.$store.commit('clearError'), 2000)
    },
    async getData(page = 1) {
      if (this.gets.getData) return
      this.gets.getData = true
      this.page = page
      // if (this.$route.name === 'cinema-id') return
      const { data } = await this.$axios.get(`/api/need-friends?page=${page}`)
      if (data.error) {
        console.log(data.error)
      } else {
        if (!data.length) {
          this.showMore = false
          return
        } else if (data.length < 10) {
          this.showMore = false
        }
        const arr = page > 1 ? this.usersdata.concat(data) : data
        this.usersdata = arr
        this.$nextTick(() => this.lazyLoadImgs())
      }
      delete this.gets.getData
    }
  }
}
</script>

<style src="~/assets/nf.styl" lang="stylus" />
